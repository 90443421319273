import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CTable02,
  CBreadCrumb,
  LContact03,
  CSectMedia,
} from '../../../../components/_index';
import '../../../../assets/_sass/page/garden.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: '日本庭園・茶室「耕雲亭」',
            sub: 'JAPANESE GARDEN・TEA ROOM',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/facility/garden/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/facility/garden/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_garden">
        <section className="u_pt100">
          <LWrap>
            <CSectMedia
              data={[
                {
                  img: {
                    src: '/assets/images/about/facility/garden/img_garden.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/facility/garden/img_garden__sp.png',
                    alt: '',
                  },
                  text: (
                    <>
                      <p className="u_mb20">
                        この茶室は、三菱の第二代社長岩崎彌之助、及びその嗣子で第四代社長小彌太の父子二代によって設立され、かつて静嘉堂文庫（東京都世田谷区）にありました茶室「釣月庵」を模したものであります。
                      </p>
                      <p className="">
                        「釣月庵」は小彌太によって営まれ、永平寺の管長
                        秦慧昭禅師が道元禅師の偈中の句「釣月耕雲古風を慕う」から採って命名したものであります。
                        当茶室は、これらの縁由に基づき、耕雲亭と命名いたしました。
                      </p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/about/facility/garden/img_garden02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/facility/garden/img_garden02__sp.png',
                    alt: '',
                  },
                  text: (
                    <>
                      <p className="u_mb20">
                        都会の喧騒から離れ、庭園に囲まれた静かな茶室で、一服のお茶をおたのしみいただきたい。ロイヤルパークホテル日本庭園内にございます茶室「耕雲亭」は、そのような願いからつくられました。
                      </p>
                      <p>
                        日本独自の美意識である建物と庭との「和」を尊重いたしましたその佇まいは、ここが都心であることを、暫し、忘れさせてくれることでしょう。四季折々の美しい庭を眺めながら、大切な一時をお過ごしいただければ幸いです。
                      </p>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pb100">
          <LWrap exClass="p_garden_table">
            <p className="title u_mb25">茶室「耕雲亭」</p>
            <CTable02
              exClass="u_mb20"
              data={[
                {
                  title: 'ご利用料金',
                  content: <>茶室席料 110,000円</>,
                },
                {
                  title: '道具料',
                  content: (
                    <>
                      水屋用具一式 11,000円
                      <br className="u_sp" />
                      茶道具 3,300円
                    </>
                  ),
                },
                {
                  title: 'ご利用時間',
                  content: <>10:00〜16:00</>,
                },
                {
                  title: 'お問合せ',
                  content: <>日本料理 源氏香にて承ります。</>,
                },
              ]}
            />
            <ul className="c_noteList">
              <li>料金には消費税が含まれます。</li>
            </ul>
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title="お問合せ"
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-3667-1111',
          subText: '（代表）',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
